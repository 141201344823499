// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
@include mat.legacy-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$zanatta-primary: mat.define-palette(mat.$indigo-palette);
$zanatta-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$zanatta-warn: mat.define-palette(mat.$red-palette);

$zanatta-typography: mat.define-typography-config(
  $font-family: Roboto,
);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$zanatta-theme: mat.define-light-theme(
  (
    color: (
      primary: $zanatta-primary,
      accent: $zanatta-accent,
      warn: $zanatta-warn,
    ),
    typography: $zanatta-typography,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($zanatta-theme);
@include mat.all-legacy-component-themes($zanatta-theme);

/* You can add global styles to this file, and also import other style files */

html {
  font-size: 65.5% !important;
}

body {
  padding: 0;
  margin: 0;
  font-family: Roboto, sans-serif;
  font-size: 1.4rem;
}

.col-padding {
  margin: 0;
  padding: 0;
}

.mat-icon-medium {
  font-size: 40px;
}

.maozinha {
  cursor: pointer;
}

// tamanho de textos globais

.titulo {
  font-size: 1.8rem !important;
  font-family: Roboto, sans-serif;
}

.subtitulo {
  font-size: 1.6rem !important;
  font-family: Roboto, sans-serif;
}

.text-font {
  font-size: 1.4rem !important;
  font-family: Roboto, sans-serif;
}

.text-font-top {
  font-size: 2rem !important;
  font-family: Roboto, sans-serif;
}

.text-op {
  font-size: 1.2rem !important;
  font-family: Roboto, sans-serif;
}

.text-font-mediun {
  font-size: 1.6rem !important;
  font-family: Roboto, sans-serif;
}

.text-font-medium {
  font-size: 1.6rem !important;
  font-family: Roboto, sans-serif;
}

.nao-selecionar {
  -webkit-touch-callout: none;
  /* iPhone OS, Safari */
  -webkit-user-select: none;
  /* Chrome, Safari 3 */
  -khtml-user-select: none;
  /* Safari 2 */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+ */
  user-select: none;
  /* Possível implementação no futuro */
}

// snack-bar
.snack-bar-success {
  .mdc-snackbar__surface {
    border-radius: 4px;
    font-size: 1.4rem;
    background-color: hsl(122, 39%, 49%) !important;
    color: white;
  }

  button {
    color: white !important;
  }
}

.snack-bar-info {
  .mdc-snackbar__surface {
    border-radius: 4px;
    font-size: 1.4rem;
    background-color: #015a2e !important;
    color: white;
  }

  button {
    color: #ff1744 !important;
  }
}

.snack-bar-error {
  .mdc-snackbar__surface {
    background-color: #ef5350 !important;
    border-radius: 4px;
    font-size: 1.4rem;
    color: white;
  }
  button {
    color: white !important;
  }
}

.snack-bar-alert {
  .mdc-snackbar__surface {
    border-radius: 4px;
    font-size: 1.4rem;
    background-color: #e0b73c !important;
    color: black;
  }

  button {
    color: black !important;
  }
}

.tabela {
  padding: 0 calc(var(--bs-gutter-x) * 0.5);
}

.color-text-sub {
  color: #a3a5ac;
}

.placeholder {
  background: none !important;
  opacity: 1;
}

mat-form-field .mat-form-field {
  &-underline {
    position: static;
  }
  &-subscript-wrapper {
    position: static;
  }
}

.input-new {
  input {
    background: transparent;
    border: none;
    padding: 0;
  }

  input:focus {
    outline-width: 0;
  }

}
